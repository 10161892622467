<template>
  <div>
    <div class="center" v-if="showLoading">
		  <lottie :options="defaultOptionsSplashScreen" :height="300" :width="300" v-on:animCreated="handleAnimationSplashScreen"/>
	  </div>
    <div v-else>
      <headerSection></headerSection>
      <div id="main__content" class="">
        <div class="x-main-container">
          <div class="x-main-content">
            <div class="x-promotion-index" :style="{ 'background-image': `url(${backgroundImage})`,'background-position': 'center','background-repeat': 'no-repeat','background-size': 'cover'}">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="x-page-title-component -long-support">
                                <div class="-inner-wrapper">
                                <h1 class="-title" style="font-size:20px;color:#fbc844;">{{$t('boxs_page.title')}}</h1>
                                </div>
                            </div>
                            <p class="-description">{{$t('boxs_page.title2')}}</p>
                        </div>
                    </div>
                </div>
            <!-- 'height':'100vh'-->
              <div class="container">
                <div class="x-wrapper-right-container"></div>
                  <div id="contact_gl" class="section" style="padding-top: -100px !important;">
                      <div style="position: absolute; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                    <div style="position: absolute; top: -10px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                      <lottie :options="defaultOptionsEmpty1" :height="300" :width="300"  v-on:animCreated="handleAnimationEmpty1" />
                    </div>
                    <div style="position: absolute; top: -10px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                      <lottie :options="defaultOptionsPoint1" :height="300" :width="300"  v-on:animCreated="handleAnimationPoint1" />
                    </div>
                    <div style="position: absolute; top: -10px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                      <lottie :options="defaultOptions1" :height="300" :width="300" v-on:animCreated="handleAnimation1" />
                      <div @click="selectimage2(1)" style="position: absolute; top: 100px; width: 150px; height: 150px;" />
                    </div>


                    <div style="position: absolute; top: 190px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                      <lottie :options="defaultOptionsEmpty2" :height="300" :width="300" v-on:animCreated="handleAnimationEmpty2" />
                    </div>
                    <div style="position: absolute; top: 190px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                      <lottie :options="defaultOptionsPoint2" :height="300" :width="300"  v-on:animCreated="handleAnimationPoint2" />
                    </div>
                    <div style="position: absolute; top: 190px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                      <lottie :options="defaultOptions2" :height="300" :width="300" v-on:animCreated="handleAnimation2" />
                      <div @click="selectimage2(2)" style="position: absolute; top: 100px; width: 150px; height: 150px;" />
                    </div>


                    <div style="position: absolute; top: 390px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                      <lottie :options="defaultOptionsEmpty3" :height="300" :width="300" v-on:animCreated="handleAnimationEmpty3" />
                    </div>
                    <div style="position: absolute; top: 390px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                      <lottie :options="defaultOptionsPoint3" :height="300" :width="300"  v-on:animCreated="handleAnimationPoint3" />
                    </div>
                    <div style="position: absolute; top: 390px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                      <lottie :options="defaultOptions3" :height="300" :width="300" v-on:animCreated="handleAnimation3" />
                      <div @click="selectimage2(3)" style="position: absolute; top: 100px; width: 150px; height: 150px;" />
                    </div>
                    </div>
                  </div>
              </div>
              <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <p class="glow">{{loadingtext}}</p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <footerSection></footerSection>
      </div>
    </div>
  </div>
</template>
<script>
import '../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../assets/fonts/iconic/css/material-design-iconic-font.min.css'
import '../assets/css/main.css'
import '../assets/css/util.css'
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
// const axios = require("axios").default;
import moment from 'moment-timezone'
import router from './../router'
import Lottie from './lottie.vue'
import * as animBox1 from '../assets/Box1.json'
import * as animBoxEmpty from '../assets/Box3.json'
import * as animBoxCoin from '../assets/Box4.json'
//import * as animateSantaWalk from '../assets/loading.json'
import * as animateSantaWalk from '../assets/shiba_loading1.json'
const axios = require('axios').default
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
  name: 'Gift',
  created () {
    const user = localStorage.getItem('userData')
	  if (user) {
		  const _user = JSON.parse(user)
		  this.$store.dispatch('user/setUser', _user).then(()=>{
        this.checkUserId()
      }).catch(err => { console.error(err) })
	  } else {
      firebase.auth().signOut()
      localStorage.removeItem('userData')
      router.replace('/login')
	  }
    const animate1 = animBox1.default
    const animate2 = animBox1.default
    const animate3 = animBox1.default
    const animateSplashScreen = animateSantaWalk.default
    this.defaultOptions1 = { animationData: animate1, autoplay: false, loop: true }
    this.defaultOptionsEmpty1 = { animationData: animBoxEmpty.default, autoplay: false, loop: false }
    this.defaultOptionsPoint1 = { animationData: animBoxCoin.default, autoplay: false, loop: false }
    this.defaultOptions2 = { animationData: animate2, autoplay: false, loop: true }
    this.defaultOptionsEmpty2 = { animationData: animBoxEmpty.default, autoplay: false, loop: false }
    this.defaultOptionsPoint2 = { animationData: animBoxCoin.default, autoplay: false, loop: false }
    this.defaultOptions3 = { animationData: animate3, autoplay: false, loop: true }
    this.defaultOptionsEmpty3 = { animationData: animBoxEmpty.default, autoplay: false, loop: false }
    this.defaultOptionsPoint3 = { animationData: animBoxCoin.default, autoplay: false, loop: false }
    this.defaultOptionsSplashScreen = { animationData: animateSplashScreen, autoplay: false, loop: false }
  },
  mounted () {
    const width = screen.width
    if(width <= 480)
      this.backgroundImage = require('../assets/images/Shiba-BG.jpg')
    else
      this.backgroundImage = require('../assets/images/Shiba-BG-Desktop.jpg')
    if (!firebase.auth().currentUser) {
      router.replace('/login')
    } else {
      this.animSplashScreen.play()
      
      setTimeout(() => {
        this.showLoading = false
        this.animSplashScreen.stop()
        setTimeout(() => {
          this.animempty1.hide()
          this.animempty2.hide()
          this.animempty3.hide()
          this.animpoint1.hide()
          this.animpoint2.hide()
          this.animpoint3.hide()
          this.anim1.play()
          this.anim2.play()
          this.anim3.play()
        }, 500)
        this.checkUserId();
      }, 3500)
    }
    //this.getGames()
  },
  data () {
    return {
      defaul1:1,
      logoLeft:'',
      logoRight:'',
      gameKey: '3boxs',
      showLoading: true,
      pictureUrl: '',
      code: '',
      search: '',
      loading: false,
      error: false,
      web: [],
      message: 'Invalid user name or password!',
      point: 0,
      favorites: [],
      animationInfinite: {
        classes: 'fadeIn',
        duration: 5000,
        iteration: 'infinite'
      },
      permission: [],
      loadingtext: '',
      defaultOptions1: null,
      defaultOptions2: null,
      defaultOptionsSplashScreen: null,
      openGiftData: false,
      canOpen: false,
      backgroundImage: ''
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue'),
    lottie: Lottie
  },
  methods: {
    async checkUserId () {
      console.log('checkUserId',this.user.key)
      await firebase.firestore().collection('spin').where('userkey','==',this.user.key).where('game','==','boxs').where('date','==',moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).get().then(async (snapshot) => {
        if (snapshot.size > 0) {
          this.$confirm(
            {
              message: this.$t('boxs_page.message'),
              button: {
                yes: 'OK'
              },
              callback: confirm => {
                if (confirm) {
                  this.$router.push('/')
                }
              }
            }
          )
        } else {
          await firebase.firestore().collection('daily_deposit').doc(this.user.key + '-' + moment().tz('Asia/Bangkok').add(-1, 'days').format('YYYY-MM-DD')).get().then(async (doc) => {
          //await firebase.firestore().collection('history').where('userkey','==',this.user.key).where('type','==','ฝาก').where('status','==','ดำเนินการแล้ว').where('createdate','>=',moment().tz("Asia/Bangkok").format("YYYY-MM-DD")+' 00:00:00').where('createdate','<=',moment().tz("Asia/Bangkok").format("YYYY-MM-DD")+' 23:59:59').get().then(async (snapshot) => {
            // let credit = 0;
            // snapshot.forEach((doc)=>{
            //   credit += Number(doc.data().qty);
            // });
            // if(credit >= 250){
            //   this.canOpen = true;
            // }else{
            //   const less = 250-Number(credit);
            //   this.loadingtext = `เติมเงินอีก ${less}(250) ได้เล่นเลยค่ะ!😘😘`;
            // }
            if (doc.exists) {
              if (doc.data().cashback >= 250) {
                this.canOpen = true
              } else {
                const less = 250 - Number(doc.data().cashback)
                // this.loadingtext = `เติมเงินอีก ${less}(250) ได้เล่นเลยค่ะ!😘😘`;
                this.loadingtext = this.$t('boxs_page.message2',{less:less})
              }
            } else {
              this.loadingtext = this.$t('boxs_page.message2',{less:250})
            }
          });
        }
      })
    },
    getValue(min,max) {
         return Math.floor(Math.random()*(max-min+1)+min);
    },
    selectimage2(i) {
      if(this.canOpen) {
        this.canOpen = false
        let item = 0
        const ignore = ['SHIBA38120', 'SHIBA38119', 'SHIBA38118', 'SHIBA38085']
        if (ignore.includes(this.user.code)) {
          item = this.getValue(0,5);
        } else {
          item = this.getValue(0,15);
        }
        if(i==1) {
          this.anim1.hide()
          if(item) {
            this.animpoint1.show()
            this.animpoint1.play()
          }else{
            this.animempty1.show()
            this.animempty1.play()
          }
        }else if(i==2) {
          this.anim2.hide()
          if(item) {
            this.animpoint2.show()
            this.animpoint2.play()
          }else{
            this.animempty2.show()
            this.animempty2.play()
          }
        }else{
          this.anim3.hide()
          if(item) {
            this.animpoint3.show()
            this.animpoint3.play()
          }else{
            this.animempty3.show()
            this.animempty3.play()
          }
        }
        firebase.firestore().collection('spin').where('usekey','==',this.user.key).where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).where('game', '==', 'boxs').get().then(async (snapshot) => {
          if (snapshot.size === 0) {
            await firebase.firestore().collection('spin').add({
              userkey: this.user.key,
              code: this.user.code,
              credit: item,
              createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
              value: Number(item),
              game: 'boxs',
              status: false
            }).then((result) => {
              axios({
                method: 'post',
                url: 'https://api.shiba888.bet/qspin/boxs',
                data: {
                  key: result.id
                }
              })
            }).catch((error) => {
              console.error('Error writing document: ', error)
            });
          }
        })
        // axios({
        //   method: 'post',
        //   url: 'https://api.shiba888.bet/qspin/boxs',
        //   data: {
        //     key: this.user.key,
        //     code: this.user.code,
        //     credit: item
        //   }
        // });
        setTimeout(() => {
          if(item){
            this.$confirm(
              {
                message: `💎 ปังปุรีเย่!! คุณได้รับ ${item} เครดิต 😘`,
                button: {
                  yes: 'OK'
                },
                callback: confirm => {
                  if (confirm) {
                    // this.getUserId()
                  }
                }
              }
            )
          }else{
            this.$confirm(
              {
                message: `💎 แป่วว!! เสียใจด้วยนะคะพรุ่งนี้มาลองเปิดใหม่นะคะ 😘`,
                button: {
                  yes: 'OK'
                },
                callback: confirm => {
                  if (confirm) {
                    // this.getUserId()
                  }
                }
              }
            )
          }
        }, 3000);
        // if(this.games_web.type === 'credit'){
        //     firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
        //         [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
        //     })
        // }else{
        //     this.point = this.point + Number(item)
        //     const update = {}
        //     update[`games/${this.gameKey}`] = firebase.database.ServerValue.increment(-1)
        //     update[`point`] = firebase.database.ServerValue.increment(Number(item))
        //     firebase.database().ref(`users/${this.$projectId}/${this.user.key}`).update(update)
        // }
        // firebase.firestore().collection('transections').add({
        //     code: this.user.code,
        //     mobile: this.user.mobile,
        //     createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
        //     game: this.gameKey,
        //     game_name: this.games_web.name,
        //     qty: Number(item),
        //     type: this.games_web.type,
        //     status: (this.games_web.type === 'credit')?false : true,
        //     web: this.$projectId
        // })
        // setTimeout(() => {
        //   if(item){
        //     this.$confirm(
        //     {
        //       message: `📦 ปังปุรีเย่!! คุณได้รับ ${item} ${this.games_web.type} 😘😘`,
        //       button: {
        //         yes: 'OK'
        //       },
        //       callback: confirm => {
        //         if (confirm) {
        //             router.replace('/games')
        //         }
        //       }
        //     });
        //   }else{
        //     this.$confirm(
        //     {
        //       message: `📦 งือ ๆ เสียใจด้วยไว้มาลุ้นกันใหม่นะคะ`,
        //       button: {
        //         yes: 'OK'
        //       },
        //       callback: confirm => {
        //         if (confirm) {
        //             router.replace('/games')
        //         }
        //       }
        //     });
        //   }
        // }, 3000);
      }
        //this.selectimage();
    },
    // selectimage() {
    //   if(this.canOpen) {
    //     this.canOpen = false;
    //     const item = this.getValue();
    //     if(this.games_web.type === 'credit') {
    //         firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
    //             [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
    //         })
    //     }else{
    //         this.point = this.point + Number(item)
    //         const update = {}
    //         update[`games/${this.gameKey}`] = firebase.database.ServerValue.increment(-1)
    //         update[`point`] = firebase.database.ServerValue.increment(Number(item))
    //         firebase.database().ref(`users/${this.$projectId}/${this.user.key}`).update(update)
    //     }
    //     firebase.firestore().collection('transections').add({
    //         code: this.user.code,
    //         mobile: this.user.mobile,
    //         createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
    //         game: this.gameKey,
    //         game_name: this.games_web.name,
    //         qty: Number(item),
    //         type: this.games_web.type,
    //         status: false,
    //         web: this.$projectId
    //     })
    //     if(item) {
    //       this.$confirm(
    //       {
    //         message: `📦 ปังปุรีเย่!! คุณได้รับ ${item} ${this.games_web.type} 😘😘`,
    //         button: {
    //           yes: 'OK'
    //         },
    //         callback: confirm => {
    //           if (confirm) {
    //               router.replace('/games')
    //           }
    //         }
    //       });
    //     }else{
    //       this.$confirm(
    //       {
    //         message: `📦 งือ ๆ เสียใจด้วยไว้มาลุ้นกันใหม่นะคะ`,
    //         button: {
    //           yes: 'OK'
    //         },
    //         callback: confirm => {
    //           if (confirm) {
    //               router.replace('/games')
    //           }
    //         }
    //       });
    //     }
    //   }
      
    // },
    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    handleAnimationSplashScreen: function (anim) {
      this.animSplashScreen = anim
    },
    handleAnimation1: function (anim) {
      this.anim1 = anim
    },
    handleAnimationEmpty1: function(anim) {
      this.animempty1 = anim
    },
    handleAnimationPoint1: function(anim) {
      this.animpoint1 = anim
    },
    handleAnimation2: function (anim2) {
      this.anim2 = anim2
    },
    handleAnimationEmpty2: function(anim) {
      this.animempty2 = anim
    },
    handleAnimationPoint2: function(anim) {
      this.animpoint2 = anim
    },
    handleAnimation3: function (anim3) {
      this.anim3 = anim3
    },
    handleAnimationEmpty3: function(anim) {
      this.animempty3 = anim
    },
    handleAnimationPoint3: function(anim) {
      this.animpoint3 = anim
    },
    checkCssBox (game) {
      if (!game.status) {
        return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
      }
    },
    checkCss (game) {
      if (!this.permission || !this.permission[game.key]) {
        return 'filter: grayscale(100%);'
      }
      // const index = _.findIndex(this.favorites, function(o) { return o == code; });
      // if (index>-1) {
      //   return '';
      // } else {
      //   return 'filter: grayscale(100%);';
      // }
    },
    favorite (gameCode) {
      const index = _.findIndex(this.favorites, function (o) { return o == gameCode })
      if (this.userId) {
        if (index > -1) {
          firebase.database().ref('favorites/' + this.userId + '/' + gameCode).remove()
          this.favorites.splice(index, 1)
        } else {
          firebase.database().ref('favorites/' + this.userId + '/' + gameCode).push({
            gameCode
          })
          this.favorites.push(gameCode)
        }
      }
    },
    goto (type) {
      if (type === 'home') {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // });
        this.$router.push('/games').catch(() => {})
      } else if (type === 'website') {
        window.location = this.web.link
        // this.gotoline()
      } else if (type === 'contact') {
        window.location = this.web.link_line
        // this.gotoline()
      }
    },
    getSpinClass () {
      if (!this.loading) { return 'fas fa-sync-alt f-9' } else { return 'fas fa-sync-alt fa-spin f-9' }
    },

    numberWithCommas (x) {
      return Number(x)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    openGame (game) {
      if (this.permission[game.key]) {
        if (game.key === 'gift') {
          this.$router.push('/gift').catch(() => {})
        }
      }
    }
  }
}
</script>
<style scoped>
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
	/*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
	background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
.boxColumn{
    justify-content: center;
    flex-direction: row;
    display: flex;
  }
.boxImage{
  width: 300px; 
  height: 300px;
} 
.divImage {
  height: 200px;
  padding-top:150px;
  text-align: center; 
  cursor:pointer;
}
.animOver {
  position: absolute; 
  left: 0; 
  right: 0; 
  top: 30px; 
  bottom: 0;
}
@media (max-device-width: 480px){
	.boxColumn{
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .boxImage{
    width: 200px; 
    height: 200px;
  } 
  .divImage {
    height: 200px;
    padding-top:50px;
    text-align: center; 
    cursor:pointer;
  }
  .animOver {
    position: absolute; 
    left: 0; 
    right: 0; 
    top: 10px; 
    bottom: 0;
  }
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
	 0% {
		 background-position: 0% 50%;
	}
	 100% {
		 background-position: 100% 50%;
	}
}
.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }
    
    to {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }
}
</style>

<template>
  <div>
      <div class="preloader" v-if="loading">
      <div class="circular-spinner">
        <img src="https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2Floading-shiba.png?alt=media&token=ce1dc70f-dbae-4794-a8a2-cd1cdee32723">
      </div>
      </div>
  <div class="container">
    <div class="x-language">
						<div class="current-lang -link-wrapper" @click="toggleDropdown">
							<div class="-image-wrapper">
								<img 
									class="img-fluid -ic-right-star-rank" 
									:src="getFlagImage()" 
									alt="Shiba888 change language"
								>
								<span class="lang-name" v-if="isDropdownOpen">{{ getLanguageName() }}</span>
							</div>
						</div>
						<div class="lang-dropdown" v-if="isDropdownOpen">
							<div 
								v-for="lang in languages" 
								:key="lang.code"
								class="lang-option"
								@click="selectLanguage(lang.code)"
							>
								<img 
								:src="require(`../assets/images/flags/${lang.code}.png`)" 
								:alt="lang.name"
								class="flag-icon"
								>
								<span class="lang-name">{{ lang.name }}</span>
							</div>
						</div>
			</div>
    <div class="signup-space">
      <div class="signup-stars"></div>
      <div class="signup-stars"></div>
      <div class="signup-stars"></div>
      <div class="signup-stars"></div>
      <div class="signup-stars"></div>
      <div class="signup-stars"></div>
    </div>
    <img src="https://github.githubassets.com/images/modules/site/home/hero-glow.svg" alt="Glowing universe" class="js-warp-hide position-absolute overflow-hidden home-hero-glow events-none">
      <div class="row">
          <div class="col-md-5 mx-auto">
              <div id="first">
                  <div class="myform form ">
                      <div class="logo mb-3">
                          <div class="col-md-12 text-center">
                              <img src="../assets/images/logo-shiba8881.png"
                                  style="max-width: 100%;margin-bottom: 15px;">
                              <div v-if="pictureUrl">
                                <img :src="pictureUrl" width="60" style="border-radius: 50%;">
                              </div>
                              <h2 v-if="step!==4">{{$t('register_page.title')}}</h2>
                              <template v-else>
                                  <h3 class="text-center text-white" style="font-size:20px;font-weight: 500;color: #fec33f !important;">{{$t('register_page.register_success')}}</h3>
                              </template>
                          </div>
                      </div>
                      <form id="msform" name="msform" method="post" onsubmit="return false;">
                      <div class="x-step-register" v-if="step!=4">
                          <div class="px-0 m-auto -container-wrapper">
                              <div :class="{ '-step-box-outer': true, 'step-active': step==1 }"></div>
                              <div :class="{ '-step-box-outer': true, 'step-active': step==2 }"></div>
                              <div :class="{ '-step-box-outer': true, 'step-active': step==3 }"></div>
                          </div>
                      </div>
                      <fieldset v-if="step==1">
                          <p class="mt-1 text-white small" style="text-align: center;">
                            {{$t('register_page.please_fill_data')}}</p>
                          <div class="form-group">
                              <div class="input-group mb-4">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon1">
                                          <i class="fa fa-user"></i>
                                      </span>
                                  </div>
                                  <input type="number" v-model="mobile" id="mobile" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10" class="form-control" :placeholder="$t('login_page.mobile')" aria-describedby="basic-addon1">
                              </div>
                          </div>
                          <div class="col-md-12 mb-3" style="padding-left:0px;padding-right:0px;">
                              <p class="text-center">
                                  <a class="btn btn-blu-grow" @click="checkPhone()">{{$t('register_page.next')}} <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                              </p>
                          </div>
                          <div class="col-md-12 mb-3 text-center" style="padding-top:20px;padding-left:0px;padding-right:0px;" v-if="!userId">
                                <button type="button" class="google btn mybtn cCsozd ewHin" @click="lineLogin()"><img class="icon" src="../assets/images/icon-line.svg" alt="line">{{$t('login_page.login_with_line')}}</button>
                            </div>
                            <div class="col-md-12 mb-3 text-center" style="padding-top:0px;padding-left:0px;padding-right:0px;" v-if="!email">
                                <button type="button" class="google btn mybtn cCsozd ewHin" style="background:#0782ED" @click="facebookLogin()"><img class="icon" src="../assets/images/facebook.png" alt="line">{{$t('login_page.login_with_facebook')}}</button>
                            </div>
                            <div class="col-md-12 mb-3 text-center" style="padding-top:0px;padding-left:0px;padding-right:0px;" v-if="!gmail">
                                <button type="button" class="google btn mybtn cCsozd ewHin" style="background:#FFF;color:#000;" @click="googleLogin()"><img class="icon" src="../assets/images/google.png" alt="line">{{$t('login_page.login_with_google')}}</button>
                            </div>
                      </fieldset>
                      <fieldset v-if="step==2">
                          <p class="mt-1 text-white small" style="text-align: center;">{{$t('register_page.please_fill_password')}}
                          </p>
                          <div class="form-group">
                              <input type="password" v-model="password" class="form-control mb-3" :placeholder="$t('login_page.password')"
                                  id="password" name="password">
                              <input type="password" v-model="password2" class="form-control" :placeholder="$t('register_page.confirm_password')"
                                  id="con_password">
                          </div>
                          <div class="col-md-12 mb-3" style="padding-left:0px;padding-right:0px;">
                              <p class="text-center">
                                  <a class="btn btn-blu-grow" @click="checkPassword()">{{$t('register_page.next')}} <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                              </p>
                          </div>
                          <a href="javascript:;" @click="step=1" class="previous action-button-previous"><i
                                  class="fa fa-angle-left" aria-hidden="true" style="color: #eee;"></i>
                                  {{$t('register_page.back')}}</a>
                      </fieldset>
                      <fieldset v-if="step==3">
                          <p class="mt-1 text-white small" style="text-align: center;">{{$t('register_page.please_select_bank')}}</p>
                          <div class="form-group">
                              <select v-model="bank" class="form-control mb-3" @change="recheckAutoGenName()">
                                  <option value="0">{{$t('register_page.select_bank')}}</option>
                                  <option value="truewallet">TrueWallet</option>
                                  <option value="bankokbank">BBL - ธนาคารกรุงเทพ</option>
                                  <option value="kbank">KBANK - ธนาคารกสิกรไทย</option>
                                  <option value="scb">SCB - ธนาคารไทยพาณิชย์</option>
                                  <option value="tisco">TISCO - ธนาคารทิสโก้</option>
                                  <option value="ttb">TTB - ทหารไทยธนชาต</option>
                                  <option value="ktb">KTB - ธนาคารกรุงไทย</option>
                                  <option value="krungsri">BAY - ธนาคารกรุงศรีอยุธยา</option>
                                  <option value="kiatnakin">KKP - ธนาคารเกียรตินาคิน</option>
                                  <option value="gsb">GSB - ธนาคารออมสิน</option>
                                  <option value="lh">LHBANK - ธนาคารแลนด์ แอนด์ เฮ้าส์</option>
                                  <option value="uob">UOB - ธนาคารยูโอบี</option>
                                  <option value="ธนาคารอิสลาม">IBANK - ธนาคารอิสลามแห่งประเทศไทย</option>
                                  <option value="ghbank">GHB - ธนาคารอาคารสงเคราะห์</option>
                                  <option value="cimb">CIMB - ธนาคารซีไอเอ็มบีไทย</option>
                                  <option value="baac">BAAC - ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร (ธกส)</option>
                                  <option value="icbc">ICBC - ธนาคารไอซีบีซี (ไทย)</option>
                                  <option value="standard">STANDARD - ธนาคารสแตนดาร์ดชาร์เตอร์ดไทย</option>
                              </select>

                              <input type="number" class="form-control mb-3" placeholder="เลขที่บัญชี" v-model="account_no" @blur="checkBankAccount()">
                              <input type="text" class="form-control mb-3" placeholder="ชื่อ" v-model="firstname" :readonly="autogenname">
                              <input type="text" class="form-control " placeholder="นามสกุล" v-model="lastname" :readonly="autogenname">
                              <!-- <div>
                                  <label for="f-option" class="l-radio">
                                      <input type="radio" id="f-option" name="prostatus" value="Y" tabindex="1">
                                      <span>รับโบนัส</span>
                                  </label>
                                  <label for="s-option" class="l-radio">
                                      <input type="radio" id="s-option" name="prostatus" value="N" tabindex="2"
                                          checked="checked">
                                      <span>ไม่รับโบนัส</span>
                                  </label>
                              </div> -->

                          </div>
                          <div class="col-md-12 mb-3" style="padding-left:0px;padding-right:0px;">
                              <p class="text-center">
                                  <a class="btn btn-blu-grow" @click="register()">{{$t('register_page.register')}} <i class="fa fa-user-circle" aria-hidden="true"></i></a>
                              </p>
                          </div>
                          <a href="javascript:;"  @click="step=2" class="previous action-button-previous"><i
                                  class="fa fa-angle-left" aria-hidden="true" style="color: #eee;"></i>
                                  {{$t('register_page.back')}}</a>
                      </fieldset>
                      <fieldset v-if="step==4">
                          <p class="mt-1 text-white small" style="text-align: center;">{{$t('register_page.thank_for_register')}}</p>
                          <div class="bg_register_user text-left">
                              <h4 style="color: #000 !important;font-size:20px;">{{$t('register_page.username')}}: <span id="txt_username">{{mobile}}</span></h4>
                              <h4 style="color: #000 !important;font-size:20px;">{{$t('register_page.password')}}: <span id="txt_password">{{password}}</span></h4>
                          </div>
                           <div class="col-md-12 mb-3" style="padding-left:0px;padding-right:0px;">
                              <p class="text-center">
                                  <a class="btn btn-blu-grow" @click="login()">{{$t('register_page.login')}} <i class="fa fa-sign-in" aria-hidden="true"></i></a>
                              </p>
                          </div>
                      </fieldset>
                      </form>
                      <template v-if="step!=4">
                          <div class="col-md-12 ">
                              <div class="login-or">
                                  <hr class="hr-or">
                                  <span class="span-or">{{$t('register_page.or')}}</span>
                              </div>
                          </div>

                          <div class="col-md-12 mb-3">
                              <div class="text-center" style="color: #FFF;">
                                {{$t('register_page.have_account')}}   <router-link to="/login" style="color: #fec33f;text-decoration: underline !important;">{{$t('register_page.login')}}</router-link> / <a href="javascript:;" @click="openLine()" style="color: rgb(254, 195, 63)">{{$t('register_page.contact_admin')}}</a>
                              </div>
                          </div>
                      </template>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
</template>

<script>

import queryString from 'querystring'
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
const axios = require('axios').default
const qs = require('qs')
export default {
name: 'Login',
async created () {
  const user = localStorage.getItem('userData')
  if (user) {
    const _user = JSON.parse(user)
    if (_user && _user.key && firebase.auth().currentUser) {
      router.replace('/')
    }
  }
  const { code, prefix, ref } = queryString.parse(window.location.search.replace('?', ''))
  if (prefix) {
    localStorage.setItem('prefix', prefix)
  }
  if (ref) {
    localStorage.setItem('ref', ref)
  }
  if (!code) return
  const result = await this.getToken(code)
  if (result && result.access_token) {
    const { data } = await this.getProfile(result.access_token)
    this.checkLineLogin(data)
  }
},
data () {
  return {
    isDropdownOpen: false,
      languages: [
        { code: 'th', name: 'ไทย' },
        { code: 'en', name: 'English' },
        { code: 'my', name: 'Myanmar' },
        { code: 'lo', name: 'ລາວ' }
      ],
    autogenname:false,
    mobile: '',
    step: 1,
    password: '',
    password2: '',
    bank: 0,
    firstname: '',
    lastname: '',
    account_no: '',
    loading: false,
    userFriendId: '',
    clientId: '1656935750',
    callbackUri: 'https://shiba888.bet/register',
    clientSecret: '4b4c6d35256b0034244f1a0056ddb640',
    pictureUrl: '',
    userId: '',
    email: '',
    displayName: '',
    gmail: '',
  }
},
mounted () {
  // this.getSetting();
  const userData = localStorage.getItem('userId')
  const userDataFacebook = localStorage.getItem('faceBook')
  const userDataGoogle = localStorage.getItem('google')
  if (userData) {
    try {
      const user = JSON.parse(userData)
      this.userId = user.userId
      this.pictureUrl = user.pictureUrl
    } catch (error) {

    }
  }else if(userDataFacebook){
     try {
      const user = JSON.parse(userDataFacebook)
      this.email = user.email
      this.displayName = user.displayName
    } catch (error) {

    }
  }else if(userDataGoogle){
     try {
      const user = JSON.parse(userDataGoogle)
      this.gmail = user.gmail
      this.displayName = user.displayName
    } catch (error) {

    }
  }
  const userData2 = JSON.parse(localStorage.getItem('userData')) || {};
    const newLang = userData2.language;
    if (newLang) {
			this.$i18n.locale = newLang;
		} else {
			this.$i18n.locale = 'th'; // default to Thai
		}
},
methods: {
  toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectLanguage(langCode) {
      // Update user language in database
      const userData = JSON.parse(localStorage.getItem('userData')) || {};
      userData.language = langCode;
      localStorage.setItem('userData', JSON.stringify(userData));
      
      // Update Vuex store
      this.$i18n.locale = langCode;
      
      this.isDropdownOpen = false;
    },
    getLanguageName () {
      const userData = JSON.parse(localStorage.getItem('userData')) || {};
      const language = userData.language;
      const validLanguages = ['my', 'en', 'lo', 'th'];
      if (validLanguages.includes(language)) {
        return this.languages.find(lang => lang.code === language).name;
      }
      return 'ไทย';
    },
    getFlagImage() {
      const userData = JSON.parse(localStorage.getItem('userData')) || {};
      const language = userData.language;
      const validLanguages = ['my', 'en', 'lo', 'th'];
      
      if (validLanguages.includes(language)) {
      return require(`../assets/images/flags/${language}.png`);
      }
      
      return require('../assets/images/flags/th.png');
    },
  recheckAutoGenName() {
    if(this.bank === 'truewallet'){ 
      this.autogenname = false;
      this.firstname = '';
      this.lastname = '';
    }else{
      this.autogenname = false;
    }
    if(this.bank && this.account_no){
      this.checkBankAccount();
    }
  },
  openLine () {
    window.location = 'https://lin.ee/zE0q6vc'
  },
  googleLogin() {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then((result) => {
      var credential = result.credential;
      var token = credential.accessToken;
      var user = result.user;
      // console.log('credential',credential);
      // console.log('token',token);
      // console.log('user',user);
      if(user && user.email){
          this.loading = true
          axios({
            method: 'post',
            url: 'https://api.shiba888.bet/api/logingoogle',
            data: {
              displayName: user.displayName,
              email: user.email
            }
          }).then(async res => {
            if (res && res.data && res.data.status) {
              firebase.auth().signInWithEmailAndPassword(res.data.mobile.trim().toLowerCase() + '@shiba888.com', res.data.password.trim()).then(async (res2) => {
                if (res2.user) {
                  localStorage.setItem('userData', JSON.stringify(res.data))
                  this.loading = false
                  router.replace('/')
                } else {
                  this.error = this.$t('login_page.please_contact_admin')
                }
                this.loading = false
              }).catch(() => {
                this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.please_contact_admin') })
                this.loading = false
              })
            }else{
              localStorage.setItem('google', JSON.stringify(user))
              this.gmail = user.email
              this.displayName = user.displayName
              this.loading = false
            }
          })
        }
    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
  },
  facebookLogin(){
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    // provider.setCustomParameters({
    //   'display': 'popup'
    // });
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        var credential = result.credential;

        // The signed-in user info.
        var user = result.user;
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var accessToken = credential.accessToken;
        if(user && user.email){
          this.loading = true
          axios({
            method: 'post',
            url: 'https://api.shiba888.bet/api/loginfacebook',
            data: {
              displayName: user.displayName,
              email: user.email
            }
          }).then(async res => {
            if (res && res.data && res.data.status) {
              firebase.auth().signInWithEmailAndPassword(res.data.mobile.trim().toLowerCase() + '@shiba888.com', res.data.password.trim()).then(async (res2) => {
                if (res2.user) {
                  localStorage.setItem('userData', JSON.stringify(res.data))
                  this.loading = false
                  router.replace('/')
                } else {
                  this.error = this.$t('login_page.please_contact_admin')
                }
                this.loading = false
              }).catch(() => {
                this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.please_contact_admin') })
                this.loading = false
              })
            }else{
              localStorage.setItem('faceBook', JSON.stringify(user))
              this.email = user.email
              this.displayName = user.displayName
              this.loading = false
            }
          })
        }
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;

        // ...
      });
  },
  lineLogin () {
    const url = 'https://access.line.me/oauth2/v2.1/authorize'
    let params = {
      response_type: 'code',
      client_id: this.clientId,
      redirect_uri: this.callbackUri,
      state: Math.random().toString(32).substring(2),
      scope: 'openid profile'
    }
    if (this.addFriend) {
      params = Object.assign(params, {
        prompt: 'consent',
        bot_prompt: 'aggressive'
      })
    }
    window.location.href = `${url}?${queryString.stringify(params)}`
  },
  checkLineLogin (data) {
    this.loading = true
    axios({
      method: 'post',
      url: 'https://api.shiba888.bet/api/loginline',
      data: {
        userId: data.userId,
        pictureUrl: data.pictureUrl
      }
    }).then(async res => {
      if (res && res.data && res.data.status) {
        firebase.auth().signInWithEmailAndPassword(res.data.mobile.trim().toLowerCase() + '@shiba888.com', res.data.password.trim()).then(async (res) => {
          if (res.user) {
            router.replace('/')
          } else {
            this.error = this.$t('login_page.please_contact_admin')
          }
          this.loading = false
        }).catch(() => {
          this.$swal({ icon: 'error', title: this.$t('login_page.text_header'), text: this.$t('login_page.please_contact_admin') })
          this.loading = false
        })
      } else {
        localStorage.setItem('userId', JSON.stringify(data))
        this.userId = data.userId
        this.pictureUrl = data.pictureUrl
        this.loading = false
      }
    })
  },
  async getToken (code) {
    const data = qs.stringify({
      grant_type: 'authorization_code',
      code,
      redirect_uri: this.callbackUri,
      client_id: this.clientId,
      client_secret: this.clientSecret
    })
    let response
    await axios({
      method: 'post',
      url: 'https://api.line.me/oauth2/v2.1/token',
      data: data
    }).then(async res => {
      if (res.status !== 200) {
        this.error = this.$t('login_page.please_contact_admin')
      } else if (res.data) {
        response = res.data
      }
    })
    return response
  },
  async getProfile (token) {
    return await axios.get('https://api.line.me/v2/profile', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  },
  async checkFriend (token) {
    const { data } = await axios.get('https://api.line.me/friendship/v1/status', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data.friendFlag
  },
  async register () {
    if (this.bank === 0) {
      this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.please_select_bank') })
    } else if (this.account_no.trim() === '') {
      this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.fill_account_no') })
    } else if (this.firstname.trim() === '') {
      this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.fill_name') })
    } else if (this.lastname.trim() === '') {
      this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.fill_lastname') })
    } else {
      this.loading = true
      let check = false
      if(this.bank !== 'truewallet')
      check = await this.checkBankAccount()
      if (!check) {
        const checkname = await this.checkName()
        if (!checkname) {
          axios({
            method: 'post',
            url: 'https://api.shiba888.bet/api/createuser',
            data: {
              firstname: this.firstname.toString().trim(),
              lastname: this.lastname.toString().trim(),
              bank: this.bank,
              account_no: this.account_no,
              tel: this.mobile,
              password: this.password,
              userFriendId: (localStorage.getItem('ref')) ? localStorage.getItem('ref') : '',
              agent: (localStorage.getItem('prefix')) ? localStorage.getItem('prefix') : '',
              userId: this.userId,
              email: this.email,
              gmail: this.gmail,
              displayName: this.displayName,
              pictureUrl: this.pictureUrl
            }
          }).then(async response => {
            this.loading = false
            if (!response.data.status) {
              this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.please_contact_admin') })
            } else {
              this.step = 4
              localStorage.removeItem('userId')
              localStorage.removeItem('faceBook')
              localStorage.removeItem('google')
              localStorage.removeItem('prefix')
              localStorage.removeItem('ref')
            }
          })
          this.step = 4
        }
      }
      this.loading = false
    }
  },
  login () {
      // console.log(this.mobile);
      // console.log(this.password);
      const keygen = Math.random().toString(32).substring(2)
      axios({
        method: 'post',
        url: 'https://api.shiba888.bet/api/login',
        data: {
          mobile: this.mobile,
          password: this.password,
          keygen: keygen
        }
      }).then(async res => {
        if (res.data && !res.data.status) {
          this.$swal({ 
            icon: 'error', 
            title: this.$t('login_page.text_header'), 
            text: this.$t('login_page.mobile_password_error') 
          });
          this.loading = false;
        } else {
          try {
            const userCredential = await firebase.auth()
              .signInWithEmailAndPassword(
                this.mobile.trim().toLowerCase() + '@shiba888.com', 
                this.password.trim()
              );

            if (userCredential.user) {
              // Get existing user data
              const userData = JSON.parse(localStorage.getItem('userData')) || {};
              
              // Merge API response with existing data
              const updatedUserData = {
                ...res.data,
                language: userData.language || this.$i18n.locale || 'th', // เพิ่ม fallback,
                keygen: keygen
              };

              // Save to localStorage
              localStorage.setItem('userData', JSON.stringify(updatedUserData));
              
              // Update Vuex store if needed
              if (this.$store.dispatch) {
                await this.$store.dispatch('user/setUser', updatedUserData);
              }

              // Update i18n locale
              this.$i18n.locale = updatedUserData.language;
              
              this.loading = false;
              router.replace('/');
            } else {
              this.$swal({ 
                icon: 'error', 
                title: this.$t('login_page.text_header'), 
                text: this.$t('login_page.please_contact_admin') 
              });
              this.loading = false;
            }
          } catch (error) {
            this.$swal({ 
              icon: 'error', 
              title: this.$t('login_page.text_header'), 
              text: this.$t('login_page.mobile_password_error') 
            });
            this.loading = false;
          }
        }
      }).catch(error => {
        this.$swal({ 
          icon: 'error', 
          title: this.$t('login_page.text_header'), 
          text: this.$t('login_page.mobile_password_error') 
        });
        this.loading = false;
      });
    },
  async checkBankAccount () {
    if (this.account_no && this.bank) {
      let check = false
      await axios({
        method: 'post',
        url: 'https://api.shiba888.bet/getnameauto',
        data: {
          accountno: this.account_no.trim(),
          bank: this.bank
        }
      }).then(async response => {
        if (response.data.status) {
          //const n = response.data.name.split(' ');
          // if(n.length>2){
          //   this.firstname = n[1];
          //   this.lastname = n[2];
          // }else{
          //   this.firstname = n[0];
          //   this.lastname = n[1];
          // }
        }else if (!response.data.status) {
          if(response.data.msg == 'ไม่สามารถตรวจสอบชื่อบัญชีได้'){
            // this.firstname = ''
            // this.lastname = ''
            this.autogenname = false;
          }else{
            this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.account_no_exist') })
            this.account_no = ''
            // this.firstname = ''
            // this.lastname = ''
            check = true
          }
        }
      })
      return check
    }
  },
  async checkName () {
    let check = false
    await axios({
      method: 'post',
      url: 'https://api.shiba888.bet/api/checkname',
      data: {
        firstname: this.firstname.toString().trim(),
        lastname: this.lastname.toString().trim()
      }
    }).then(async response => {
      if (!response.data.status) {
        this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.name_exist') })
        this.firstname = ''
        this.lastname = ''
        check = true
      }
    })
    return check
  },
  gotoLogin () {
    router.replace('/login')
  },
  checkPassword () {
    if (this.password.toString().trim().length < 6) {
      this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.check_password_length') })
    } else if (this.password !== this.password2) {
      this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.password_not_match') })
    } else {
      this.step = 3
    }
  },
  async checkPhone () {
    if (!this.mobile.toString().trim()) {
      this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.fill_mobile') })
    } else if (this.mobile.toString().trim().length !== 10) {
      this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.check_mobile_length') })
    } else {
      axios({
        method: 'get',
        url: 'https://api.shiba888.bet/api/checkmobile/' + this.mobile.trim()
      }).then(async response => {
        if (!response.data.status) {
          this.$swal({ icon: 'error', title: 'Error!', text: this.$t('register_page.mobile_exist') })
        } else {
          this.step = 2
        }
      })
    }
  }
}
}
</script>
<style scoped>
.x-step-register {
  margin: 0rem 0 2.2rem;
}
.m-auto {
  margin: auto!important;
}
.x-step-register .-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  background: linear-gradient(
90deg
,#fe8a02,#ffe867);
  overflow: hidden;
}
.x-step-register .-container-wrapper .-step-box-outer.step-active {
  background-color: transparent;
}
.x-step-register .-container-wrapper .-step-box-outer {
  display: flex;
  justify-content: flex-end;
  width: 40px;
  height: 8px;
  background-color: #d2d2d2;
}
.x-step-register .-container-wrapper .-step-box-outer:not(:last-of-type):after {
  content: "";
  display: block;
  width: 2px;
  height: 8px;
  background-color: #6C0D0E;
}
.ewHin {
  background: rgb(0, 187, 0);
  color: rgb(255, 255, 255);
  height: 50px;
  min-width: 180px;
  border-radius: 25px;
  padding-left: 34px;
  padding-right: 34px;
}
.cCsozd {
  display: inline-block;
  padding: 0.5rem 1rem;
  height: 44px;
  font-size: 1em;
  line-height: 1.5;
  border: none;
  border-radius: 0.25rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
}
.ewHin .icon {
  height: 18px;
  margin: 0px 0.5rem;
  vertical-align: sub;
  border: 0px;
}
.home-hero-glow {
  top: 50%;
  left: 50%;
  width: 200%;
  transform: translate(-50%, -50%);
}
.events-none {
  pointer-events: none;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-absolute {
  position: absolute !important;
}
</style>
<style lang="scss">
.signup-space,
.signup-stars {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
overflow: hidden;
}

.signup-stars {
background-image:
  radial-gradient(2px 2px at 50px 200px, #eee, rgba(0, 0, 0, 0)),
  radial-gradient(2px 2px at 40px 70px, #fff, rgba(0, 0, 0, 0)),
  radial-gradient(3px 4px at 120px 40px, #ddd, rgba(0, 0, 0, 0));
background-repeat: repeat;
background-size: 250px 250px;
opacity: 0;
animation: zoom 5s infinite;

&:nth-child(1) {
  background-position: 10% 90%;
  animation-delay: 0s;
}

&:nth-child(2) {
  background-position: 20% 50%;
  background-size: 270px 500px;
  animation-delay: 0.3s;
}

&:nth-child(3) {
  background-position: 40% -80%;
  animation-delay: 1.2s;
}

&:nth-child(4) {
  background-position: -20% -30%;
  transform: rotate(60deg);
  animation-delay: 2.5s;
}

&:nth-child(5) {
  background-image:
    radial-gradient(2px 2px at 10px 100px, #eee, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 20px 10px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(3px 4px at 150px 40px, #ddd, rgba(0, 0, 0, 0));
  background-position: 80% 30%;
  animation-delay: 4s;
}

&:nth-child(6) {
  background-position: 50% 20%;
  animation-delay: 6s;
}
}
@keyframes zoom {
0% {
  opacity: 0;
  transform: scale(0.5);
  transform: rotate(5deg);
  animation-timing-function: ease-in;
}

85% {
  opacity: 1;
}

100% {
  opacity: 0.2;
  transform: scale(2.2);
}
}
@media (prefers-reduced-motion) {
.signup-stars {
  animation: none;
}
}
</style>

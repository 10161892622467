<template>
  <div>
    <headerSection></headerSection>
    <div class="x-main-content" :style="{ 'background-image': `url(${backgroundImage})`,'background-position': 'center','background-repeat': 'no-repeat','background-size': 'cover'}"><!--,'background-size':'900px 700px','background-image': `url(${require('../assets/images/Shiba-BG1.jpg')})` -->
      <div id="main_content_recommend" class="container">
    <section class="row x-category-index" >
        <div class="col-sm-12 col-lg-12 col-12 recommend_section" style="padding-top:25px;">
            <fieldset class="form-group text-center mb-0 px-1">
                <div class="row mt-2 mb-0 mb-4 justify-content-md-center">
                    <div class="col-sm-12 col-lg-12 col-12">
                        <h3 class="text-center mb-3" style="font-weight: 500;">{{$t('link_partner')}}</h3>
                        <img src="https://firebasestorage.googleapis.com/v0/b/shiba-88.appspot.com/o/images%2F-Mz_m0Rf-hUPdBhEvbeI.png?alt=media&token=51684235-f2cb-4617-abe4-f0eeffffa14e" class="-cover-img img-fluid" style="padding-bottom:20px;">
                        <div role="group" class="input-group">
                                <input id="copyTarget3" type="text" readonly="readonly" class="form-size-14 form-control" :value="`https://shiba888.bet/register?ref=${user.code}`">
                            <div class="input-group-append">
                                <button id="copyButton3" class="btn btn-copy-link btn-sm btn_bg" v-clipboard:copy="`https://shiba888.bet/register?ref=${user.code}`" v-clipboard:success="clipboardSuccessHandler"><i class="fa fa-copy fa-sm mr-1"></i>{{ $t('copy') }} </button>
                            </div>
                        </div>
                        <fieldset class="form-group">
                            <div class="row mt-3 mb-0 justify-content-md-center" style="color:#000;">
                                    <div class="col-6">
                                       <input id="r1" type="radio" name="radio" value="1" :checked="bonusfriendtype=='ยอดฝาก'" @change="changeBonus('ยอดฝาก')">
                                      <!-- <input id="s1" type="checkbox" class="switch" :checked="bonusfriendtype=='ยอดฝาก'" @change="changeBonus('ยอดเสีย')"> -->
                                      <label for="s1">{{$t('deposit_title')}}</label>
                                    </div>
                                    <div class="col-6">
                                      <input id="r2" type="radio" name="radio" value="2" :checked="bonusfriendtype=='ยอดเสีย'" @change="changeBonus('ยอดเสีย')">
                                      <!-- <input id="s2" type="checkbox" class="switch" :checked="bonusfriendtype=='ยอดเสีย'" @change="changeBonus('ยอดฝาก')"> -->
                                      <label for="s2">{{$t('deposit_title2')}}</label>
                                    </div>
                            </div>
                        </fieldset>
                        <p class="mt-4 mb-0" style="font-family: Kanit;color:#000;"> {{$t('deposit_title3')}} </p>
                    </div>
                </div>
            </fieldset>

            <div class="text-title text-center" style="font-family: Kanit;">{{$t('partner_title')}}</div>
            <div class="row no-gutters">
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;color:#000;">{{$t('partner_title2')}} ({{(bonusfriendtype==='ยอดฝาก')?master.level1_bonus:master.level11_bonus}}%)</p>
                        <p class="mb-0">{{(user.level1)?Object.keys(user.level1).length:0}}</p>
                        <img src="../assets/images/analysis.png" width="30" style="cursor:pointer" @click="showlevel(1)">
                    </div>
                </div>
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;color:#000;">{{$t('partner_title3')}} ({{(bonusfriendtype==='ยอดฝาก')?master.level2_bonus:master.level22_bonus}}%)</p>
                        <p class="mb-0">{{(user.level2)?Object.keys(user.level2).length:0}}</p>
                        <img src="../assets/images/analysis.png" width="30" style="cursor:pointer" @click="showlevel(2)">
                    </div>
                </div>
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;color:#000;">{{$t('partner_title4')}}</p>
                        <p class="mb-0">{{ (partner && partner.total1)?numberWithCommas(partner.total1):0}}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;color:#000;">{{$t('partner_title5')}}</p>
                        <p class="mb-0">{{ (partner && partner.total2)?numberWithCommas(partner.total2):0}}</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card-report text-center">
                        <p class="mb-1" style="font-family: Kanit;color:#000;">{{$t('partner_title6')}}</p>
                        <p class="mb-0">{{ (partner && partner.total)?numberWithCommas(partner.total):0}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center" style="padding-top:22px;">
                    <a href="javascript:void(0)" @click="movePartnerToWallet()" class="text-white js-account-approve-aware btn" :style="checkStyle()" data-toggle="modal">
                        <div style="font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">{{$t('partner_title7')}}</div>
                    </a>
                </div>
        </div>
        <div class="col-sm-12 col-lg-12 col-12 recommend_section">
          <p style="text-align: center;padding:30px;font-size: 16px;font-family: Kanit;color:#000;">
              <b class="tt_l tt_full fr_tx1" style="font-size:20px;" v-html="$t('partner_page.share_link_title', {
                bonus: (bonusfriendtype==='ยอดฝาก') ? master.level1_bonus : master.level11_bonus,
                type: $t(`deposit_type.${(bonusfriendtype === 'ยอดฝาก') ? 'deposit' : 'loss'}`)
              })"></b>
              ({{ $t('partner_page.copy_to_earn') }})
              <span style="color:#FAB537">{{ $t('partner_page.share_more') }}</span>
              <br><br>
              
              {{ $t('partner_page.share_description', {
                bonus: (bonusfriendtype==='ยอดฝาก') ? master.level1_bonus : master.level11_bonus
              }) }}
              <br><br>
              
              * <span style="color:#FAB537">{{ $t('partner_page.daily_earn') }}</span>
              {{ $t('partner_page.calculation_example', {
                type: $t(`deposit_type.${(bonusfriendtype === 'ยอดฝาก') ? 'deposit' : 'loss'}`),
                bonus: (bonusfriendtype==='ยอดฝาก') ? master.level1_bonus : master.level11_bonus,
                result: (bonusfriendtype==='ยอดฝาก') ? '1,600.00' : '8,000'
              }) }}
              
              {{ $t('partner_page.lifetime_commission') }}
              <br>
              {{ $t('partner_page.monthly_potential') }}
            </p>
        </div>
    </section>
</div>
</div>


<div data-loading-container=".modal-body" tabindex="-1" class="modal x-modal -promotion-detail-modal promotion-detail-modal-27 show" aria-modal="true" :style="`display: ${(showdetail)?`block`:'none'}; padding-right: 8px;`"><div class="modal-dialog -modal-size  " role="document" style="padding-top: 152.8px;">
    <div class="modal-content -modal-content" :style="{ 'background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}">
                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close" @click="closeDetail()">
                <i class="fas fa-times"></i>
            </button>
    <div class="modal-body -modal-body" style="top: 0px;">
        <div class="container">
            <div class="row">
                <div class="col-12 mt-4">
                    <div class="x-page-title-component ">
                      <div class="-inner-wrapper">
                        <h1 class="-title">{{titleDetail}}</h1>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column">
          <div class="-real-content">
                    
            <div class="x-card card -card-promotion-detail ">
                                            
            <div style="padding-top:20px;">
                *ข้อมูลสรุปยอดเมื่อวาน
                <div class="x-promotion-content">
                  <div class="fixTableHead">
                  <table class="tablecontainer">
                    <thead>
                      <tr>
                        <th><h1 style="text-align:center">รหัส</h1></th>
                        <th><h1 style="text-align:center">วันที่</h1></th>
                        <th><h1 style="text-align:center">ฝาก</h1></th>
                        <th><h1 style="text-align:center">ถอน</h1></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in history" :key="index">
                        <td style="text-align:center">{{item.code}}</td>
                        <td style="text-align:center">{{item.createdateShow}}</td>
                        <td style="text-align:right">{{ numberWithCommas(item.deposit) }}</td>
                        <td style="text-align:right">{{ numberWithCommas(item.withdraw) }}</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
            </div>

                            <div class="card-footer">
                    <!-- <button class="btn -btn -get-promotion-btn js-promotion-apply " data-toggle="modal" data-target="#depositModal" data-promotion-target="promotion-detail-modal-27" data-type="deposit" data-dismiss="modal" data-url="/promotion/27/apply">
                        <span>รับโปรโมชั่น</span>
                    </button> -->
                </div>
                        </div>
                </div>
                                    <div class="mx-3 mb-3">
                        <div class="x-admin-contact -no-fixed">
                            <span class="x-text-with-link-component" style="font-size:15px;font-family: 'Kanit', sans-serif;">
     <label class="-text-message ">ติดปัญหา&nbsp;</label>&nbsp;
     <a href="https://lin.ee/zE0q6vc" class="-link-message " target="_blank" rel="noopener">
        <u>ติดต่อฝ่ายบริการลูกค้า</u>
    </a>
</span>
                        </div>
                    </div>
                                    </div>
            </div>
    </div>
</div></div>

<footerSection></footerSection>
</div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
import moment from 'moment-timezone'
const axios = require('axios').default
export default {
  name: 'Partner',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  created () {
	  const user = localStorage.getItem('userData')
	  if (user) {
		  const _user = JSON.parse(user)
		  this.$store.dispatch('user/setUser', _user).catch(err => { console.error(err) })
	  } else {
      firebase.auth().signOut()
      localStorage.removeItem('userData')
      router.replace('/login')
	  }
  },
  data: function () {
    return {
      partner: [],
      backgroundImage:'',
      showdetail: false,
      titleDetail: '',
      history:[],
      bonusfriendtype:'ยอดฝาก',
      master:[],
    }
  },
  mounted () {
    const width = screen.width
    if(width <= 480)
      this.backgroundImage = require('../assets/images/Shiba-BG.jpg')
    else
      this.backgroundImage = require('../assets/images/Shiba-BG-Desktop.jpg')
    this.scrollToTop()
    this.getPartner()
    if(this.user && this.user.bonusfriendtype){
      this.bonusfriendtype = this.user.bonusfriendtype
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  methods: {
    changeBonus(bonus){
      this.bonusfriendtype = bonus
      if(this.user && this.user.key){
        firebase.database().ref(`users/${this.user.key}`).update({bonusfriendtype:bonus});
        this.$swal({ icon: 'success', title: 'พันธมิตร', text: `คืน${bonus} จะคำนวนในวันถัดไปค่ะ` })
      }
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    movePartnerToWallet () {
      if (this.partner.total && this.partner.key) {
        this.$store.commit('user/SET_LOADING', true)
        axios.get(`https://api.shiba888.bet/qpartner/partner/${this.partner.key}`)
        setTimeout(() => {
          this.getPartner()
          this.$store.commit('user/SET_LOADING', false)
        }, 2000)
      }
    },
    clipboardSuccessHandler () {
      this.$swal({ icon: 'success', title: 'Copy', text: 'คัดลอกเรียบร้อยแล้ว' })
    },
    checkStyle () {
      if (this.partner && this.partner.total > 0) {
        return 'background: linear-gradient(180deg,#fbc844,#f88012);height: 43px;min-width:120px;padding-left: 24px;padding-right: 24px;padding-top: 0.8em;'
      } else {
        return 'background: linear-gradient(180deg,#cccccc,#cccccc);height: 43px;min-width:120px;padding-left: 24px;padding-right: 24px;padding-top: 0.8em;'
      }
    },
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/login')
      }
    },
    numberWithCommas (x) {
	  if (!x) x = 0
      return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async getPartner () {
      const user = this.getUserId()
      if (user) {
        this.master = (await firebase.database().ref(`/partner`).once('value')).val();
        firebase.firestore().collection('partner').where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
          if (!snapshot.size) this.partner = []
          snapshot.forEach((doc) => {
            const data = doc.data()
            data.key = doc.id
            this.partner = data
          })
        })
      }
    },
    async showlevel(level){
      const history = [];
      const deposit = [];
      const withdraw = [];
      if(level === 1){
        this.titleDetail = 'แนะนำเพื่อน';
        await firebase.firestore().collection('daily_deposit').where('createdate', '==', moment().tz('Asia/Bangkok').add(-1,'days').format('YYYY-MM-DD')).where('level1', '==', this.user.code).get().then((snapshot) => {
          snapshot.forEach((doc)=>{
            deposit[doc.data().code] = doc.data().total;
          });
        });
        await firebase.firestore().collection('daily_withdraw').where('createdate', '==', moment().tz('Asia/Bangkok').add(-1,'days').format('YYYY-MM-DD')).where('level1', '==', this.user.code).get().then((snapshot) => {
          snapshot.forEach((doc)=>{
            withdraw[doc.data().code] = doc.data().total;
          });
        });
        console.log(deposit);
        console.log(withdraw);
        if(this.user.level1){
          for(const x in this.user.level1){
            if(deposit[this.user.level1[x].code]){
              this.user.level1[x].deposit = deposit[this.user.level1[x].code];
            }else{
              this.user.level1[x].deposit = 0;
            }
            if(withdraw[this.user.level1[x].code]){
              this.user.level1[x].withdraw = withdraw[this.user.level1[x].code];
            }else{
              this.user.level1[x].withdraw = 0;
            }
            this.user.level1[x].createdateShow = moment().add(-1,'days').format('DD MMM,YY')
            history.push(this.user.level1[x]);
          }
        }
      }else{
        this.titleDetail = 'เพื่อนแนะนำเพื่อน';
        await firebase.firestore().collection('daily_deposit').where('createdate', '==', moment().tz('Asia/Bangkok').add(-1,'days').format('YYYY-MM-DD')).where('level2', '==', this.user.code).get().then((snapshot) => {
          snapshot.forEach((doc)=>{
            deposit[doc.data().code] = doc.data().total;
          });
        });
        await firebase.firestore().collection('daily_withdraw').where('createdate', '==', moment().tz('Asia/Bangkok').add(-1,'days').format('YYYY-MM-DD')).where('level2', '==', this.user.code).get().then((snapshot) => {
          snapshot.forEach((doc)=>{
            withdraw[doc.data().code] = doc.data().total;
          });
        });
        if(this.user.level2){
          for(const x in this.user.level2){
            if(deposit[this.user.level2[x].code]){
              this.user.level2[x].deposit = deposit[this.user.level2[x].code];
            }else{
              this.user.level2[x].deposit = 0;
            }
            if(withdraw[this.user.level2[x].code]){
              this.user.level2[x].withdraw = withdraw[this.user.level2[x].code];
            }else{
              this.user.level2[x].withdraw = 0;
            }
            this.user.level2[x].createdateShow = moment().add(-1,'days').format('DD MMM,YY')
            history.push(this.user.level2[x]);
          }
        }
      }
      this.history = history;
      this.showdetail = true;
    },
    closeDetail(){
      this.showdetail = false;
    }
  }
}
</script>
<style scoped>
.mb-0 {
  color:#000;
}
@media (max-width: 500px) {
    .x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-img-category {
        width: 45px;
        height: 45px;
    }
}
@media (max-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: .8rem;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent .-menu-text .-menu-text-main {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Kanit';
    text-align: center;
    display: block;
    padding-top: 4px;
}
}
@media (min-width: 991.98px) {
.x-category-index .-nav-menu-container .-menu-parent .-list-parent .-menu-btn.-parent {
    display: flex;
}
}
@media (max-width: 990px) {
#main_content_recommend .x-category-index, #main_content_income .x-category-index {
    padding-top: 10px;
}
}

h1 {
  font-size:3em;
  font-weight: 300;
  line-height:1em;
  text-align: center;
  color: #4DC3FA;
}

h2 {
  font-size:1em;
  font-weight: 300;
  text-align: center;
  display: block;
  line-height:1em;
  padding-bottom: 2em;
  color: #FB667A;
}

h2 a {
  font-weight: 700;
  text-transform: uppercase;
  color: #FB667A;
  text-decoration: none;
}
.blue { color: #185875; }
.yellow { color: #000842; }

.tablecontainer th h1 {
  font-family: 'Kanit';
	  font-weight: bold;
	  font-size: 1.1em;
    text-align: left;
    color: #000;
}
.tablecontainer td {
  font-family: 'Kanit';
	  font-weight: normal;
	  font-size: 1em;
  -webkit-box-shadow: 0 2px 2px -2px #0E1119;
	   -moz-box-shadow: 0 2px 2px -2px #0E1119;
	        box-shadow: 0 2px 2px -2px #0E1119;
}

.tablecontainer {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  display: table;
  padding: 0 0 8em 0;
}

.tablecontainer td, .tablecontainer th {
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left:2%;
}

/* Background-color of the odd rows */
.tablecontainer tr:nth-child(odd) {
  background-color: #323C50;
}

/* Background-color of the even rows */
.tablecontainer tr:nth-child(even) {
  background-color: #2C3446;
}

.tablecontainer th {
  background-color: #e45a24;
}

.tablecontainer tr:hover {
  background-color: #464A52;
  -webkit-box-shadow: 0 6px 6px -6px #0E1119;
  -moz-box-shadow: 0 6px 6px -6px #0E1119;
      box-shadow: 0 6px 6px -6px #0E1119;
}

.tablecontainer td:hover {
  background-color: #000842;
  color: #403E10;
  font-weight: bold;

  box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
  transform: translate3d(6px, -6px, 0);

  transition-delay: 0s;
	  transition-duration: 0.4s;
	  transition-property: all;
  transition-timing-function: line;
}
.fixTableHead {
      overflow-y: auto;
      height: 500px;
    }
    .fixTableHead thead th {
      position: sticky;
      top: 0;
    }
</style>
<style lang="scss" scoped>
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #0ab215;
    --active-inner: #0ab215;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #ef0707;
    --border-hover: #275EFE;
    --background: #000;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
}
</style>
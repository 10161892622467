<template>
  <div>
    <headerSection></headerSection>

    <div id="main__content" class="">
      <div class="x-main-container">
        <div class="x-main-content">
          <!-- 'height':'100vh'-->
          <section class="x-index-top-container" :style="{ 'background-image': `url(${backgroundImage})`,'background-position': 'center','background-repeat': 'no-repeat','background-size': 'cover'}">
            <div class="x-wrapper-right-container"></div>
            <div class="-index-inner-wrapper">
              <div class="-heading-wrapper js-replace-heading-wrapper">
                <h1 class="-title">
                  {{ $t('title') }}
                </h1>
              </div>
              <swiper
                ref="mySwiper"
                :options="swiperOptions"
                style="padding-top:10px;padding-left:10px;padding-right:10px;"
              >
                <swiper-slide v-for="(item,index) in banners" :key="index">
                  <router-link to="/promotion">
                  <img
                    style="margin: auto;width: 650px;border-radius: 15px;"
                    class="img-fluid -slick-item -item-1"
                    :alt="item.name"
                    width="1200"
                    height="590"
                    :src="getLocalizedImage(item)"
                /></router-link>
                </swiper-slide>
                <!-- <div class="swiper-pagination" slot="pagination"></div> -->
              </swiper>
              <div class="d-lg-none" style="padding-top:10px;">
                <div class="x-feed-news-header">
                  <div
                    class="-feed-news-inner-wrapper animated fadeInUp"
                    data-animatable="fadeInUp"
                    data-delat="200"
                  >
                    <div class="-icon-container">
                      <i class="fas fa-volume-up"></i>
                    </div>

                    <div class="-track">
                      <div class="-track-item -duration-normal-content">
                        .. {{ $t('home_page.firsttitle') }}
                        ..
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="-games-provider-wrapper">
                <div class="-games-index-page">
                  <div
                    class="x-category-provider js-game-scroll-container js-game-container"
                  >
                    <div class="-top-paragraph">
                      <div class="container">
                        <div class="row">
                          <div class="col-12 px-2 px-lg-3">
                           <img :src="$t('images.title')" alt="Shiba888 ศูนย์รวมแบรนด์ชั้นยอด อันดับ 1" style="width: 100%;max-width: 800px;height: auto;">
                           <button v-if="deferredPrompt" @click="addToHomeScreen">Add to Home Screen</button>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="games-list-container" style="padding-top: 20px;">
                      <ul class="nav nav-pills" style="flex-wrap: nowrap;overflow-x: auto;">
                          <li v-for="(item,index) in jackpots" class="jackpot-image" style="padding-left: 15px;">
                              <div style="position: relative;">
                                        <template>
                                            <router-link :to="{ name: 'PlayGameHot',query: {id: item.id,provider:item.provider,gameCategory:item.gameCategory}}" target="_blank">
                                                <img width="300" :src="item.image.horizontal" class="-cover-img img-fluid lazyloaded">
                                            </router-link>
                                        </template>
                                      <div class="text-jackpot">${{ item.jackpot.toLocaleString() }}</div>
                              </div>
                          </li>
                      </ul>
                    </div>


                    <div class="-games-list-container container">
                      <nav class="nav-menu" id="navbarProvider">
                        <ul class="nav nav-pills">
                          <!-- <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                          <router-link to="/play" class="nav-link js-account-approve-aware">
                              <picture>
                                <img
                                  src="../assets/images/menu-winter/Menu-11.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <a
                              href="javascript:void(0)"
                              class="nav-link js-account-approve-aware"
                              target="_blank"
                              rel="nofollow noopener"
                              data-toggle="modal"
						                  data-target="#depositModal"
                              @click="checkPromotions()"
                            >
                              <picture>
                                <img
                                  src="../assets/images/menu-winter/Menu-01.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </a>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <a
                               href="javascript:void(0)"
                              class="nav-link js-account-approve-aware"
                              target="_blank"
                              data-toggle="modal"
						                  data-target="#withdrawModal"
                              @click="moveCreditToWallet()"
                            >
                              <picture>
                                <img
                                  src="../assets/images/menu-winter/Menu-02.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </a>
                          </li> -->
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/promotion" class="nav-link js-account-approve-aware">
                              <picture>
                                <img
                                  :src="$t('images.menu.promotion')"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/history" class="nav-link js-account-approve-aware">
                              <picture>
                                <img
                                  :src="$t('images.menu.history')"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/partner" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="partner">
                                  <img
                                    :src="$t('images.menu.partner')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.partner')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/continue" class="nav-link js-account-approve-aware">
                              <picture>
                                  <template v-if="continueCheck">
                                  <img
                                    :src="$t('images.menu.continue')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.continue')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/cashback" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="cashback">
                                  <img
                                    :src="$t('images.menu.cashback')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.cashback')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >

                              <picture style="cursor: pointer;" @click="clickCheckin()">
                                <template v-if="checkin">
                                  <img
                                    :src="$t('images.menu.checkin')"
                                    alt="check-in Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.checkin')"
                                    alt="check-in Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/boxs" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="boxs">
                                  <img
                                    :src="$t('images.menu.boxs')"
                                    alt="แทงหวย Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.boxs')"
                                    alt="แทงหวย Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/spin" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="spin">
                                  <img
                                    :src="$t('images.menu.spin')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.spin')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/roulet" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="roulet">
                                  <img
                                    :src="$t('images.menu.roulet')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.roulet')"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/gashapon" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="gashapon">
                                  <img
                                    :src="$t('images.menu.gashapon')"
                                    alt="กาชาปอง Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.gashapon')"
                                    alt="กาชาปอง Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/paoyingchup" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="paoyingchup">
                                  <img
                                    :src="$t('images.menu.paoyingchup')"
                                    alt="เป่ายิ้งฉุบ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.paoyingchup')"
                                    alt="เป่ายิ้งฉุบ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/huekoy" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="huekoy">
                                  <img
                                    :src="$t('images.menu.huekoy')"
                                    alt="เป่ายิ้งฉุบ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.huekoy')"
                                    alt="เป่ายิ้งฉุบ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <!-- <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/loykrathong" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="loykrathong">
                                  <img
                                    src="../assets/images/menu-winter/Menu-19.png"
                                    alt="เป่ายิ้งฉุบ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    src="../assets/images/menu-winter/Menu-19.png"
                                    alt="เป่ายิ้งฉุบ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li> -->
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/huay" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="huay">
                                  <img
                                    :src="$t('images.menu.huay')"
                                    alt="แทงหวย Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    :src="$t('images.menu.huay')"
                                    alt="แทงหวย Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/rewards" class="nav-link js-account-approve-aware">
                              <picture>
                                <template>
                                  <img
                                    :src="$t('images.menu.rewards')"
                                    alt="แลกของรางวัล Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>

                          <!-- <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/gift" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="gift">
                                  <img
                                    src="../assets/images/menu/Menu-16.png"
                                    alt="Shiba888 แจกของรางวัล คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    src="../assets/images/menu/Menu-16.png"
                                    alt="Shiba888 แจกของรางวัล คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li> -->

                          <!-- <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <a
                              href="/_ajax_/random?randomCategory=skill-game"
                              class="nav-link js-account-approve-aware"
                              target="_blank"
                              rel="nofollow noopener"
                            >
                              <picture>
                                <img
                                  src="../assets/images/menu-winter/Menu-10.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </a>
                          </li> -->
                          <!-- <li class="nav-item -random-container -game-slot-macro-container">
                            <div
                              class="x-game-list-item-macro js-game-list-toggle -big "
                              data-status=""
                            >
                              <div class="-inner-wrapper">
                                <picture>
                                  <img
                                    class="img-fluid lazyload"
                                    alt="E.t. Races | wt-evo-play สกิลเกมในเว็บคาสิโนออนไลน์ รับเครดิตฟรีที่ Shiba888"
                                    src="../assets/images/menu-winter/Menu-03.png"
                                  />
                                </picture>

                               <div class="-overlay">
                                  <div class="-overlay-inner">
                                    <div class="-wrapper-container">
                                      <a
                                        href="/account/lobby/play?channelCode=wt-evo-play&amp;metadata%5Bgame_id%5D=1012"
                                        class="-btn -btn-play js-account-approve-aware"
                                        target="_blank"
                                        rel="nofollow noopener"
                                      >
                                        <i class="fas fa-play"></i>
                                        <span class="-text-btn">เข้าเล่น</span>
                                      </a>
                                      <a
                                        href="/lobby/test?channelCode=wt-evo-play&amp;metadata%5Bgame_id%5D=1012"
                                        class="-btn -btn-demo"
                                        target="_blank"
                                        rel="noopener"
                                      >
                                        ทดลองเล่น
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li> -->
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="-live-feed-wrapper-container js-replace-live-feed-wrapper"
              ></div>
            </div>
          </section>

          <footerSection></footerSection>
        </div>
      </div>

      <div class="x-right-sidebar-container"></div>

      <div
        class="x-modal modal  "
        id="ads-no-show-again"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-loading-container=".js-modal-content"
        data-ajax-modal-always-reload="true"
        data-modal-one-time="Announcement Banner 1"
        data-check-target="#ads-no-show-again"
      >
        <div class="modal-dialog -modal-size " role="document">
          <div class="modal-content -modal-content">
            <button
              type="button"
              class="close f-1 "
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
            <div class="modal-header -modal-header">
              <h3 class="x-title-modal d-inline-block m-auto">
                <span>📢 ประกาศสำคัญ</span>
              </h3>
            </div>
            <div class="modal-body -modal-body">
              <div class="text-center" v-if="anoucement.image">
                <img
                  :src="anoucement.image"
                  alt="Announcement Shiba888"
                  class="img-fluid"
                  width="350px"
                />
              </div>
              <br>
              <div v-if="anoucement_type == 1">
                <div style="font-size:16px;font-family: 'Kanit', sans-serif;">
                  <span style="font-size:22px;">ขอต้อนรับสมาชิกใหม่ทุกท่าน </span><br><br>

                  เราคือที่สุดของระบบ และ ค่ายเกมส์ อัพเดตใหม่จากสิงคโปร์ <br>
                  <span style="font-weight: 700;color:blue">ฝากแรก!!!</span> ก็จะทำให้คุณหลงรัก shiba888 ทันที ❤️<br><br>

                  ❤️ #shiba888 ❤️ 
                </div>
              </div>
              <div v-else>
                <div v-html="nl2br(anoucement.detail)" style="font-size:15px;font-family: 'Kanit', sans-serif;"></div>
              </div>
              <div class="x-checkbox-primary">
                <div class="text-primary text-center mt-4">
                  <input
                    type="checkbox" v-model="receiveAnounceMent"
                  />&nbsp;&nbsp;&nbsp;&nbsp;
                  <label style="font-size:14px;font-family: 'Kanit', sans-serif;">
                    ไม่ต้องแสดงข้อความนี้อีก
                  </label>
                </div>
              </div>

              <div class="my-3">
                <a
                  @click="submitAnoucement()"
                  data-dismiss="modal"
                  class="text-black btn btn-block -submit btn-primary text-center m-auto"
                >
                  <span>รับทราบ</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from './../router'
import firebase from 'firebase/app'
import moment from 'moment-timezone'
const axios = require('axios').default
export default {
  name: 'Home',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  async mounted () {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    const width = screen.width
    if (width <= 480) { this.backgroundImage = require('../assets/images/Shiba-BG.jpg') } else { this.backgroundImage = require('../assets/images/Shiba-BG-Desktop.jpg') }

    this.$store.dispatch('user/getBanners').catch(err => { console.error(err) })
    this.checkAnoucement()
    await this.checkDeposit()
    await this.checkCashbackYesterday()
    this.checkCheckIn()
    this.checkBoxs()
    this.checkSpin()
    this.checkGashapon()
    this.checkRoulet()
    this.checkCashback()
    this.checkPartner()
    this.checkContinue()
    this.checkHueKoy()
    // this.checkGift()
    this.checkPaoYingChup()
    if (Number(moment().tz('Asia/Bangkok').format('D')) === 1 || Number(moment().tz('Asia/Bangkok').format('D')) === 16) {
      if (Number(moment().tz('Asia/Bangkok').format('H') + '' + moment().tz('Asia/Bangkok').format('mm')) <= 1520) {
        this.checkHuay()
      }
    }
    this.$store.dispatch('user/getJackpotGames').catch(err => { console.error(err) })
    // Snackbar.show({pos: 'top-center',text: 'Example notification text.',actionText:'Click',actionTextColor:'#ffd74c',backgroundColor:'#47124A'});
  },
  computed: {
    banners () {
      return this.$store.state.user.banners
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    lastbonus () {
      return this.$store.state.user.lastbonus
    },
    user () {
		  return this.$store.state.user.user
    },
    jackpots () {
        return this.$store.state.user.jackpot
    }
  },
  data () {
    return {
      deferredPrompt: null,
      anoucement_type: '',
      backgroundImage: '',
      cashback_yesterday: 0,
      deposit: 0,
      continueCheck: false,
      checkin: false,
      partner: false,
      cashback: false,
      roulet: false,
      spin: false,
      huay: false,
      boxs: false,
      gashapon: false,
      paoyingchup: false,
      huekoy: false,
      // gift: false,
      receiveAnounceMent: false,
      anoucement: {},
      balance: 0,
      fa_spin: false,
      swiperOptions: {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        },
        centeredSlides: true,
        spaceBetween: 30,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination'
        }
        // Some Swiper option/callback...
      }
    }
  },
  methods: {
    getLocalizedImage(item) {
      const currentLang = this.$i18n.locale;
      const imageField = `image_${currentLang}`;
      
      // ถ้ามีรูปภาพในภาษาที่ต้องการ ใช้รูปนั้น ถ้าไม่มีใช้ image_th
      return item[imageField] || item.image_th || item.image;
    },
    addToHomeScreen() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;
        });
      }
    },
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/login')
      }
    },
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    },
    async moveCreditToWallet () {
      this.$store.commit('user/SET_LOADING', true)
      const user = this.getUserId()
		  this.$store.dispatch('user/moveCreditToWallet').catch(err => { console.error(err) })
      this.$store.dispatch('user/getLastBonus', user).finally(res => {
        this.$store.commit('user/SET_LOADING', false)
        if (this.lastbonus.turncredit) {
          this.$store.commit('user/SET_ALERT_WITHDRAWALL', true)
        }
      }).catch(err => { console.error(err) })
	  },
    async clickCheckin () {
      if (this.deposit >= 100 && this.user) {
        firebase.firestore().collection('spin').doc(this.user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + '-checkin').get().then(async (snapshot) => {
          if (!snapshot.exists) {
            axios({
              method: 'post',
              url: 'https://api.shiba888.bet/qspin/checkin',
              data: {
                key: this.user.key,
                code: this.user.code,
                value: Number(5)
              }
            })
            setTimeout(() => {
              this.$swal({ icon: 'success', title: 'Success', text: this.$t('home_page.checkin_success') })
              this.checkin = false
            }, 1000)
          } else {
            this.$swal({ icon: 'error', title: 'Error!', text: this.$t('home_page.checkin_error2') })
          }
        })
      } else {
        this.$swal({ icon: 'error', title: 'Error!', text: this.$t('home_page.checkin_error') })
      }
    },
    async checkPromotions () {
      this.$store.commit('user/SET_LOADING', true)
      this.$store.dispatch('user/checkPromotions').finally(res => { this.$store.commit('user/SET_LOADING', false) }).catch(err => { console.error(err) })
    },
    async checkAnoucement () {
      const user = this.getUserId()
      let newuser = true
      await firebase.firestore().collection('history').where('userkey', '==', user.key).where('type', '==', 'ฝาก').where('status', '==', 'ดำเนินการแล้ว').limit(1).get().then(async (snapshot) => {
        if (snapshot.size > 0) {
          newuser = false
        }
      })
      if (newuser) {
        const anoucement = (await firebase.database().ref('/anoucement1/1').once('value')).val()
        if (anoucement.status) {
          if (!anoucement.users || !anoucement.users[user.key]) {
            this.anoucement = anoucement
            $('#ads-no-show-again').modal('show')
            this.anoucement_type = 1
          }
        }
      } else {
        this.anoucement_type = ''
        const anoucement = (await firebase.database().ref('/anoucement/1').once('value')).val()
        if (anoucement.status) {
          if (!anoucement.users || !anoucement.users[user.key]) {
            this.anoucement = anoucement
            $('#ads-no-show-again').modal('show')
          }
        }
      }
    },
    async checkDeposit () {
      const user = this.getUserId()
      let deposit = 0
      await firebase.firestore().collection('history').where('userkey', '==', user.key).where('type', '==', 'ฝาก').where('status', '==', 'ดำเนินการแล้ว').where('createdate', '>=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 00:00:00').where('createdate', '<=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 23:59:59').get().then(async (snapshot) => {
        snapshot.forEach((doc) => {
          deposit += Number(doc.data().qty)
        })
      })
      this.deposit = deposit
    },
    async checkCashbackYesterday () {
      const user = this.getUserId()
      await firebase.firestore().collection('daily_deposit').doc(user.key + '-' + moment().tz('Asia/Bangkok').add(-1, 'days').format('YYYY-MM-DD')).get().then(async (doc) => {
        if (doc.exists) {
          this.cashback_yesterday = doc.data().cashback
        }
      })
    },
    checkCheckIn () {
      const user = this.getUserId()
      if (this.deposit >= 100) {
        firebase.firestore().collection('spin').doc(user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + '-checkin').get().then(async (snapshot) => {
          if (!snapshot.exists) {
            this.checkin = true
          }
        })
      }
    },
    checkBoxs () {
      const user = this.getUserId()
      if (this.cashback_yesterday >= 250) {
        firebase.firestore().collection('spin').doc(user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + '-boxs').get().then(async (snapshot) => {
          if (!snapshot.exists) {
            this.boxs = true
          }
        })
      }
    },
    checkGashapon () {
      const user = this.getUserId()
      if (this.cashback_yesterday >= 500) {
        firebase.firestore().collection('spin').doc(user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + '-gashapon').get().then(async (snapshot) => {
          if (!snapshot.exists) {
            this.gashapon = true
          }
        })
      }
    },
    async checkHueKoy (){
      const user = this.getUserId()
      if (this.cashback_yesterday > 0) {
        await firebase.firestore().collection('daily_deposit').doc(user.key + '-' + moment().tz('Asia/Bangkok').add(-2, 'days').format('YYYY-MM-DD')).get().then(async (doc) => {
          if (doc.exists) {
            if(doc.data().cashback > 0 && (Number(this.cashback_yesterday) + Number(doc.data().cashback) >= 300)){
              this.huekoy = true
            }
          }
        })
      }
    },
    checkSpin () {
      const user = this.getUserId()
      if (this.deposit >= 500) {
        firebase.firestore().collection('spin').where('userkey','==',user.key).where('date','==',moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).where('game','==','spin').get().then(async (snapshot) => {
          if (!snapshot.size) {
            this.spin = true
          }
        })
      }
    },
    checkRoulet () {
      const user = this.getUserId()
      if (this.deposit >= 1000) {
        firebase.firestore().collection('roulet').where('userkey','==',user.key).where('date','==',moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).get().then(async (snapshot) => {
          if (!snapshot.size) {
            this.roulet = true
          }
        })
      }
    },
    checkHuay () {
      if (this.deposit >= 150) {
        this.huay = true
      }
    },
    checkPaoYingChup () {
      const user = this.getUserId()
      if (this.cashback_yesterday >= 1000) {
        firebase.firestore().collection('spin').doc(user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + '-paoyingchup').get().then(async (snapshot) => {
          if (!snapshot.exists) {
            this.paoyingchup = true
          }
        })
      }
    },
    // checkGift () {
    //   const user = this.getUserId()
    //   if (this.cashback_yesterday >= 500) {
    //     firebase.firestore().collection('spin').doc(user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + '-gift').get().then(async (snapshot) => {
    //       if (!snapshot.exists) {
    //         this.gift = true
    //       }
    //     })
    //   }
    // },
    checkCashback () {
      const user = this.getUserId()
      firebase.firestore().collection('cashback').where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
        if (snapshot.size) this.cashback = true
      })
    },
    checkPartner () {
      const user = this.getUserId()
      firebase.firestore().collection('partner').where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
        if (snapshot.size) this.partner = true
      })
    },
    checkContinue () {
      const user = this.getUserId()
      firebase.firestore().collection('continuous_deposit').where('date', '==', moment().tz('Asia/Bangkok').add(-1, 'days').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
        if (snapshot.size) this.continueCheck = true
      })
    },
    submitAnoucement () {
      if (this.receiveAnounceMent) {
        const user = this.getUserId()
        firebase.database().ref(`/anoucement${this.anoucement_type}/1/users`).update({
          [user.key]: true
        })
      }
    }
  }
}
</script>
<style scoped>
.x-modal .-modal-content .-modal-body {
     top: 10px;
}
@media (max-width: 575.98px) {
  .x-modal .-modal-content .-modal-body {
     top: 90px;
  }
}
@media screen and (min-width: 768px) {
    .jackpot-image {
        min-width: 450px;
    }
    .text-jackpot {
        position: absolute;
        bottom: 1%;
        right: 1%;
        color: white;
        font-size: 40px;
        padding: 3px;
        font-weight:500;
        /* background-color: rgba(17, 3, 30, 0.7); */
        /* border-radius: 10px; */
        text-shadow: 2px 2px #000;
    }
  }
  @media screen and (max-width: 767px) {
    .jackpot-image {
        min-width: 250px !important;
    }
    .text-jackpot {
        position: absolute;
        bottom: 1%;
        right: 1%;
        color: white;
        font-size: 25px;
        padding: 3px;
        font-weight:500;
        /* background-color: rgba(17, 3, 30, 0.7); */
        /* border-radius: 10px; */
        text-shadow: 2px 2px #000;
    }
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
    width: 100%;
    border-radius: 16px;
    border-style: none;
  }
</style>
